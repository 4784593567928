import React from "react";
import { Error } from "~sections/utility";
import { PageWrapper } from "~components/Core";
import FooterSix from "~components/Footer";
import HeaderButton from "~sections/marketing/Header";

const header = {
  headerClasses: `site-header site-header--menu-start light-header site-header--sticky`,
  containerFluid: !true,
  darkLogo: false,
  buttonBlock: (
    <HeaderButton
      className="ms-auto d-none d-xs-inline-flex"
      btnOneText="GET STARTED"
      mr="15px"
      mrLG="0"
    />
  ),
  // customLogo: Images.HeaderLogo,
};


export default function errorPage() {
  return (
    <PageWrapper innerPage={true} headerConfig={header}>
      <Error />
      <FooterSix />
    </PageWrapper>
  );
}
